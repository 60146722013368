import React, { useRef, useEffect } from "react";
import { Link } from "gatsby";

//Components
import Layout from "../../components/Layout";
import Category from "../../components/Category";

const data = [
  {
    title: "",
    subsubCategories: [
      {
        title:
          "Equipo neumático de soporte vital de reanimación cardiopulmonar CPR",
        subsubsubCategories: [],
      },
      {
        title: "DEA/Desfibrilador automático externo",
        subsubsubCategories: [],
      },
      {
        title:
          "Monitor Desfibrilador/Marcapasos/spO2/PP/SpCO/SpMet,SpHb/PI Presión Invasiva/PNI Presión no invasiva/Temperatura/12 derivaciones/Capnografía",
        subsubsubCategories: [],
      },
      {
        title: "Cama semiautomática multiniveles/sujetador ambulancia (cuerno)",
        subsubsubCategories: [],
      },
      {
        title: "Ventilador de traslado neonatal/pediátrico/adulto",
        subsubsubCategories: [],
      },
      {
        title: "Aspirador de secreciones",
        subsubsubCategories: [],
      },
      {
        title:
          "Laboratorio portátil de análisis clínicos (química y electrolitos séricos, hematología, gasometría, coagulación, endocrinología, marcadores cardiacos)",
        subsubsubCategories: [],
      },
    ],
  },
];

const EquipoMedicoExtra = () => {
  return (
    <Layout>
      <Category data={data} title="Equipo medico extra hospitalario" />
    </Layout>
  );
};

export default EquipoMedicoExtra;
